import React, { useState } from 'react'
import { FaFacebookSquare, FaInstagram, FaTiktok } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import { GatsbyImage } from 'gatsby-plugin-image'
import {
  COUNTRIES,
  BUY_LINK,
  PLUS_BUY_LINK,
  IS_FOREIGN_COUNTRY,
  SUPPORT_LINK,
  HIDE_PLUS,
  CONTACT_US_LINK,
  LOCALIZATION,
  IN_UK,
} from 'src/utils/constants'
import Flag from 'react-world-flags'

import {
  Section,
  Row,
  Column,
  Logo,
  Container,
  FooterLink,
  FooterLinkExternal,
  SocialLinks,
  SocialLinksContainer,
  Form,
  Contact,
  IconContainer,
  SocialMediaDesktopView,
  SocialMediaMobileView,
  FlagLink,
} from './styles'

const klaviyoFormId = process.env.GATSBY_KLAVIYO_FORM_ID
function ExternalFooterLinks(props) {
  const { name, link } = props
  return (
    <FooterLinkExternal href={link} target="_blank" rel="noopener noreferrer">
      <Trans>{name}</Trans>
    </FooterLinkExternal>
  )
}
function Social(props) {
  const { link, icon } = props
  return (
    <SocialLinks
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      aria-label="Visit Skylight Frame"
    >
      <IconContainer>{icon} </IconContainer>
    </SocialLinks>
  )
}
const Footer = ({ page, handle }) => {
  const { t } = useTranslation('common')
  const [showCountries, setShowCountries] = useState(false)

  const { X } = useStaticQuery(graphql`
    query FooterQuery {
      X: file(relativePath: { eq: "icons/X.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, placeholder: NONE, width: 14)
        }
      }
    }
  `)
  const SocialMediaSection = () => (
    <SocialLinksContainer>
      <Social
        link="https://www.facebook.com/ourskylight"
        icon={<FaFacebookSquare title={t('Skylight Share on Facebook')} />}
      />
      <Social
        link="https://www.instagram.com/skylightframe/"
        icon={<FaInstagram title={t('Skylight Share on Instagram')} />}
      />
      <Social
        link="https://www.tiktok.com/@skylightframe"
        icon={<FaTiktok title={t('Skylight Share on Tiktok')} />}
      />
      <Social
        link="https://twitter.com/skylightframe"
        icon={
          <GatsbyImage
            style={{ display: 'inline-block' }}
            image={X.childImageSharp.gatsbyImageData}
            alt="Skylight Share on X"
          />
        }
      />
    </SocialLinksContainer>
  )
  let supportLink
  if (handle?.includes('calendar') || page?.includes('calendar')) {
    supportLink = SUPPORT_LINK.calendar
  } else {
    supportLink = SUPPORT_LINK[LOCALIZATION]
  }

  return (
    <>
      <Section className="regular-cta">
        <Container>
          <Row>
            <Column>
              <Logo>Skylight</Logo>
            </Column>
            <Column space>
              <FooterLink to={BUY_LINK}>
                <Trans>Shop</Trans>
              </FooterLink>
              {!HIDE_PLUS && (
                <>
                  <FooterLink to={PLUS_BUY_LINK}>
                    <Trans>Frame Plus</Trans>
                  </FooterLink>
                  <FooterLink to="/products/calendar-skylight-plus/">
                    <Trans>Calendar Plus</Trans>
                  </FooterLink>
                </>
              )}
              <FooterLink to="/pages/about-us/">
                <Trans>About</Trans>
              </FooterLink>
              <ExternalFooterLinks name="Support" link={supportLink} />
              <FooterLink to="/blog/">
                <Trans>Blog</Trans>
              </FooterLink>
              {!IS_FOREIGN_COUNTRY && (
                <FooterLink to="/press/">
                  <Trans>Press</Trans>
                </FooterLink>
              )}
              <FooterLink to="/careers/">
                <Trans>Careers</Trans>
              </FooterLink>
              {!IS_FOREIGN_COUNTRY && (
                <ExternalFooterLinks name={t('Contact Us')} link={CONTACT_US_LINK[LOCALIZATION]} />
              )}
            </Column>
            <Column>
              {IS_FOREIGN_COUNTRY && (
                <ExternalFooterLinks name={t('Contact Us')} link={CONTACT_US_LINK[LOCALIZATION]} />
              )}
              {!IS_FOREIGN_COUNTRY && (
                <FooterLink to="/pages/refer-a-friend/">
                  <Trans>Refer a Friend</Trans>
                </FooterLink>
              )}
              <ExternalFooterLinks
                name={t('Product Recall')}
                link="http://www.recallrtr.com/skylightadapter/"
              />
              <ExternalFooterLinks
                name={t('Refund Policy')}
                link="https://skylight.zendesk.com/hc/en-us/articles/360043182612-Refund-and-Replacement-Policy"
              />
              <FooterLink to="/tos/">
                <Trans>Terms of Service</Trans>
              </FooterLink>
              <FooterLink to="/privacy-policy/">
                <Trans>Privacy Policy</Trans>
              </FooterLink>
              {IN_UK && (
                <FooterLink to="/psti-compliance/">
                  <Trans>PSTI Compliance</Trans>
                </FooterLink>
              )}
              {!IS_FOREIGN_COUNTRY && (
                <FooterLink to="/skylight-affiliate-partner-program/">
                  <Trans>Affiliates</Trans>
                </FooterLink>
              )}
              {!IS_FOREIGN_COUNTRY && (
                <FooterLink to="/pages/retailers/">
                  <Trans>Where To Buy</Trans>
                </FooterLink>
              )}
              <FooterLinkExternal onClick={() => setShowCountries(!showCountries)}>
                <Flag
                  alt={`${COUNTRIES[LOCALIZATION].country} Flag`}
                  className="flagShadow"
                  code={COUNTRIES[LOCALIZATION].abv}
                  height="20"
                />
              </FooterLinkExternal>
              {showCountries && (
                <div className="country-dropdown-content">
                  {Object.values(COUNTRIES).map((country, i) => {
                    if (country.localization !== LOCALIZATION) {
                      return (
                        <FlagLink
                          key={i}
                          href={country.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Flag
                            alt={`${country.country} Flag`}
                            className="flagShadow"
                            code={country.abv}
                            height="20"
                            align="center"
                          />
                        </FlagLink>
                      )
                    }
                    return null
                  })}
                </div>
              )}
              <SocialMediaDesktopView>
                <SocialMediaSection />
              </SocialMediaDesktopView>
            </Column>
            <Column>
              <SocialMediaMobileView>
                <SocialMediaSection />
              </SocialMediaMobileView>
            </Column>
            <Column>
              <Form>
                <div className={`klaviyo-form-${klaviyoFormId}`} />
                <Contact>
                  <Trans>You can email our support team at help@skylightframe.com</Trans>
                </Contact>
              </Form>
            </Column>
          </Row>
        </Container>
      </Section>
    </>
  )
}

ExternalFooterLinks.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
}
Social.propTypes = {
  icon: PropTypes.any,
  link: PropTypes.string,
}
Footer.propTypes = {
  page: PropTypes.string,
  handle: PropTypes.string,
}
export default Footer
