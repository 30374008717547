import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { darken, lighten } from 'polished'

import {
  breakpoints,
  contentPadding,
  BuyNowLink,
  BuyNowLinkCSS,
} from 'src/utils/styles/global-styles'

export const Container = styled.header`
  ${contentPadding}
  padding: 1rem 5vw 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.cream};
  nav > *:not(:last-child) {
    margin-right: 1.5vw;
    color: ${(props) => props.theme.grayDark};
    padding: 0.25rem;
  }
  nav > * {
    text-decoration: none;
  }
  @media (max-width: ${breakpoints.s}px) {
    nav > *:not(:last-child) {
      margin-right: 0.5vw;
    }
  }
`
const LinkStyles = css`
  color: ${(props) => props.theme.grayDarker};
  text-decoration: none;
  outline-style: none;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
    outline: none;
    outline-style: none;
  }
`
export const HideOnMobile = styled.div`
  display: inline;
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`
export const FlagStyles = css`
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.9);
  width: 36px;
  height: 18px;
  object-fit: cover;
`

export const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  line-height: none;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: row-reverse;
  }
  .dropbtn,
  .countryDropbtn {
    background-color: ${(props) => props.theme.cream};
    font-family: 'FilsonProBook';
    line-height: 1.625;
    border: none;
  }
  .dropbtn {
    font-size: 18px;
    @media (max-width: ${breakpoints.l}px) {
      font-size: 16px;
    }
  }
  .countryDropbtn {
    padding: 0px 5px;
    font-size: 25px;
    display: flex;
    align-items: center;
  }
  .dropdown {
    position: relative;
    outline: none;
    border: none;
    button {
      ${LinkStyles}
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${(props) => props.theme.cream};
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    a {
      padding: 12px 16px;
      &:hover {
        background-color: ${(props) => darken(0.1, props.theme.cream)};
      }
    }
  }
  .flagShadow {
    ${FlagStyles}
  }
  .country-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    padding: 16px 2px 8px;
    background-color: ${(props) => props.theme.cream};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .dropdown-content a,
  .country-dropdown-content a {
    text-decoration: none;
    display: block;
    font-size: 20px;
  }
  .country-dropdown-content a {
    padding: 0px;
    margin: 0px 8px;
    font-size: 20px;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown:hover .country-dropdown-content {
    display: flex;
    flex-wrap: wrap;
    width: 270px;
    justify-content: space-evenly;
    top: 26px;
    right: -10px;
  }
`
export const HomeLink = styled(Link)`
  line-height: 0;
  text-decoration: none;
`
export const LogoContainer = styled.div`
  width: 140px;
  div:nth-child(2) {
    display: none;
  }
  @media (max-width: 800px) {
    width: 130px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 28px;
    > div:first-child {
      display: none;
    }
    div:nth-child(2) {
      display: block;
    }
  }
  @media (max-width: 350px) {
    width: 25px;
  }
`
export const NavLink = styled(Link)`
  ${LinkStyles}
  font-family: 'FilsonProBook';
  font-size: 18px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
  }
  @media (max-width: 850px) {
    display: ${(props) => (props.about ? 'none' : 'block')};
  }
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`

export const NavA = styled.a`
  ${LinkStyles}
  font-family: 'FilsonProBook';
  font-size: 18px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
  }
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`
export const NavAProduct = styled.a`
  ${BuyNowLinkCSS}
  @media (max-width: ${breakpoints.s}px) {
    padding: 12px 16px;
  }
  @media (max-width: 350px) {
    font-size: 15px;
    padding: 8px 10px;
  }
`

export const MobileLink = styled(Link)`
  display: inline-block;
  ${LinkStyles}
  p {
    padding: 8px 60px;
    margin: 0px;
    color: ${(props) => props.theme.grayDark};
  }
`
export const DesktopCartLink = styled(MobileLink)`
  display: flex;
  height: auto;
  @media (max-width: ${breakpoints.m}px) {
    margin-left: 2vw;
  }
`

export const IconContainer = styled.div`
  display: none;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 28px;
    margin-right: ${(props) => (props.noMargin ? '0px' : '29px')};
    display: block;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 20px;
    display: block;
  }
`

const navLinkStyle = css`
  display: ${(props) => props.display || 'inline'};
  background-color: ${(props) => props.color || props.theme.coral};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.color || props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.color || props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.l}px) {
    padding: 14px 15px;
  }
  @media (max-width: 375px) {
    padding: 12px 12px;
    font-size: 13px;
    margin: 0px;
  }
  @media (max-width: 330px) {
    font-size: 12px;
  }
`

export const NavBuyNowLink = styled(BuyNowLink)`
  ${navLinkStyle}
`

export const NavBuyNowAnchor = styled.a`
  ${BuyNowLinkCSS}
  ${navLinkStyle}
`

export const MobileNavBuyNowLink = styled(BuyNowLink)`
  min-width: 50px;
  margin: auto;
  background-color: ${(props) => props.color || props.theme.coral};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.color || props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.color || props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    text-align: center;
    margin: 0px 20px;
    font-size: 15px;
    padding: 12px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
    margin: 0px 10px;
  }
  @media (max-width: 340px) {
    font-size: 13px;
    margin: 0px 5px;
  }
`
export const ButtonContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: center;
  padding-top: 20px;
`
export const MobileNavLinkContainer = styled.div`
  display: ${(props) => (props.showMenu ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  top: 76;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.cream};
  z-index: 999;
  visibility: ${(props) => (props.showMenu ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.showMenu ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  padding: 0px 0px ${(props) => props.dynamicPadding}px;
`
export const MobileNavLink = styled(Link)`
  width: 100%;
  font-family: 'P22MackinacProBook' !important;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.grayDarker};
  @media (max-width: ${breakpoints.m}px) {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    align-items: center;
    text-decoration: none;
  }
`
export const MobileNavLinkA = styled.a`
  ${LinkStyles}
  text-decoration: none;
  outline: none;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.grayDarker};
  font-family: 'P22MackinacProBook' !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .flagShadow {
    ${FlagStyles}
  }
  a:active,
  a:focus,
  a:visited {
    outline: none;
    border: none;
    color: ${(props) => props.theme.grayDarker};
  }
  @media (max-width: ${breakpoints.m}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }
`
export const QuantityText = styled.span`
  color: ${(props) => props.theme.grayDarker};
  z-index: 10;
  position: relative;
  top: -15px;
  left: -13px;
  font-weight: 300;
  font-size: 12px;
`

export const QuantityTextMobile = styled(QuantityText)`
  @media (max-width: ${breakpoints.l}px) {
    top: -20px;
    left: -15px;
  }
`

export const MobileCartView = styled.div`
  margin: 10px 40px;
  display: flex;
  font-family: 'FilsonPro';
  align-items: flex-end;
  @media (max-width: ${breakpoints.m}px) {
    margin: 16px 40px;
    padding-bottom: 48px;
  }
  p {
    ${LinkStyles}
    margin: -7px 10px;
    padding: 0px 0px;
    font-size: 16px;
    line-height: 1.5;
    color: ${(props) => props.theme.grayDarker};
    align-self: center;
  }
`
export const Text = styled.p`
  ${LinkStyles}
  font-family: 'P22MackinacProBook';
  padding: 0px 0px;
  margin: 16px 40px;
  font-size: 16px;
  line-height: 1.5;
  font-size: 21px;
  color: ${(props) => props.theme.grayDarker};
  .flagShadow {
    ${FlagStyles}
  }
  > img {
    margin-right: 7px;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: 5px 40px;
  }
`

export const MobileProduct = styled(MobileCartView)`
  padding-bottom: 0px;
  color: ${(props) => props.theme.grayDarker};
  margin: 12px 60px;
  ${LinkStyles}
  display: flex;
  align-items: center;
  > img {
    margin-right: 7px;
  }
`
export const ProductsLink = styled.div`
  width: 100%;
  font-family: 'P22MackinacProBook' !important;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.grayDarker};
  @media (max-width: ${breakpoints.m}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    align-items: center;
    text-decoration: none;
    margin: 4px 0px;
  }
`
export const ProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0px auto 20px;
  justify-content: space-between;
`

export const ProductCard = styled.div`
  width: 47%;
`
export const Badge = styled.span`
  position: relative;
  bottom: 5px;
  color: ${(props) => props.theme.cream};
  background-color: ${(props) => props.theme.coral};
  padding: 6px 7px;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 28px;
  @media (max-width: ${breakpoints.m}px) {
    display: flex;
    justify-content: space-between;
    display: inline-block;
    padding: 2px 5px;
    font-size: 10px;
    bottom: 2px;
  }
`
