import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Link } from 'gatsby'

export const Container = styled.div`
  background-color: ${(props) => props.bgColor || props.theme.blueDark};
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  padding: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.25px;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  font-family: 'FilsonPro';
  @media (max-width: ${breakpoints.s}px) {
    background-color: ${(props) => props.bgColor};
    padding: 15px 10px;
    font-size: 16px;
    letter-spacing: 0.06px;
    text-align: center;
    line-height: 1.3;
  }
  span {
    text-decoration: underline;
    color: ${(props) => props.theme.coral};
  }
`

export const Text = styled.p`
  color: ${(props) => props.textcolor || 'white'};
  margin: 0;
  font-family: 'FilsonProBook';
  b {
    font-family: 'FilsonProBold';
  }
  @media (max-width: 500px) {
    font-size: 13px;
  }
`

export const CalLink = styled(Link)`
  color: ${(props) => props.color || props.theme.grayDark};
  font-family: 'FilsonProBold';
  text-decoration: underline;
`
export const ALink = styled.a`
  color: ${(props) => props.color || props.theme.grayDark};
  font-family: 'FilsonProBold';
  text-decoration: underline;
`
